import React from 'react';

import GiveMeAHand from '@/contents/CampSite/other/GetMeAHand';
import type { GiveMeAHandSlice } from '@/types/prismic.generated';

const GiveMeAHandSliceComponent = ({ slice }: { slice: GiveMeAHandSlice }) => {
  return <GiveMeAHand />;
};

export default GiveMeAHandSliceComponent;
