import Box from '@mui/material/Box';
import { PrismicText } from '@prismicio/react';
import Link from 'next/link';
import React from 'react';

import Button from '@/contents/General/Button';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import ThemeCard from '@/modules/Theme/ThemeCard';
import theme from '@/styles/theme';
import type { ThemesSummarySliceFetched } from '@/types/main';

const ThemesSummary = ({ slice }: { slice: ThemesSummarySliceFetched }) => {
  return (
    <>
      <Box sx={{ paddingBottom: theme.spacing(6) }}>
        <Stack column space={2} sx={{ padding: `0 ${theme.spacing(3)}` }}>
          <Typography
            fontSize={21}
            fontWeight={700}
            variant="h2"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {slice.primary.title ? (
              <PrismicText field={slice.primary.title} />
            ) : (
              <span>Blank...</span>
            )}
          </Typography>
          <Typography fontSize={15}>
            {slice.primary.description ? (
              <PrismicText field={slice.primary.description} />
            ) : (
              <span></span>
            )}
          </Typography>
        </Stack>
        {slice.variation === 'default' && (
          <Stack
            space={2}
            sx={{
              overflowX: 'scroll',
              marginTop: theme.spacing(3),
              padding: `0 ${theme.spacing(3)}`,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {slice.themes.map((themeItem) => {
              return (
                <Box
                  key={themeItem.id}
                  sx={{
                    '& img': {
                      maxHeight: 'initial',
                    },
                  }}
                >
                  <ThemeCard
                    cardSize="lg"
                    variant="inner-title"
                    key={themeItem.id}
                    theme={themeItem}
                  />
                </Box>
              );
            })}
          </Stack>
        )}
        {slice.variation === 'typeA' && (
          <Stack
            flexWrap="wrap"
            between
            sx={{
              marginTop: theme.spacing(3),
              padding: `0 ${theme.spacing(3)}`,
            }}
          >
            {slice.themes.map((themeItem) => {
              return (
                <Box
                  key={themeItem.id}
                  sx={{ paddingBottom: theme.spacing(4) }}
                >
                  <ThemeCard variant="normal" theme={themeItem} cardSize="sm" />
                </Box>
              );
            })}
          </Stack>
        )}
        {slice.primary.button_label && slice.primary.button_link && (
          <Stack center sx={{ paddingTop: `${theme.spacing(2)}` }}>
            <Link href={slice.primary.button_link}>
              <a>
                <Button buttonSize="md" sx={{ width: '246px' }}>
                  {slice.primary.button_label}
                </Button>
              </a>
            </Link>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default ThemesSummary;
