import Box from '@mui/material/Box';
import React from 'react';

import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import theme from '@/styles/theme';
import type { RecommendedTopicsSlice } from '@/types/prismic.generated';

const RecommendedTopics = ({ slice }: { slice: RecommendedTopicsSlice }) => {
  return (
    <Box sx={{ paddingBottom: theme.spacing(6), background: '#ffffff' }}>
      <Stack
        column
        space={2}
        sx={{ padding: `${theme.spacing(6)} ${theme.spacing(3)}` }}
      >
        <Typography fontSize={21} fontWeight={700} variant="h2">
          {slice.primary.title}
        </Typography>
        <Typography fontSize={15}>{slice.primary.description}</Typography>
      </Stack>
      <Stack column space={2} sx={{ padding: `0 ${theme.spacing(3)}` }}>
        {slice.items.map((item: any) => (
          <Box key={item.image?.url ?? ''}>
            <a href={item.link}>
              <img
                src={item.image.url}
                width="100%"
                height="100%"
                alt="ヒトククリのイチ推しトピックを紹介。"
                loading="lazy"
              />
            </a>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default RecommendedTopics;
