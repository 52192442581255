import Box from '@mui/material/Box';
import * as prismicH from '@prismicio/helpers';
import { useRouter } from 'next/router';
import React from 'react';

import Button from '@/contents/General/Button';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import PostCard from '@/modules/Post/PostCard';
import theme from '@/styles/theme';
import type { PostsSummarySliceFetched } from '@/types/main';

const PostsSummary = ({ slice }: { slice: PostsSummarySliceFetched }) => {
  const router = useRouter();
  const { posts } = slice;
  return (
    <>
      <Box sx={{ padding: `${theme.spacing(6)} 0` }}>
        <Stack column space={2} sx={{ padding: `0 ${theme.spacing(3)}` }}>
          <Typography
            fontSize={21}
            fontWeight={700}
            variant="h2"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {prismicH.asText(slice.primary.title)}
          </Typography>
          <Typography fontSize={15}>{slice.primary.description}</Typography>
        </Stack>
        <Stack
          space={2}
          sx={{
            overflowX: 'scroll',
            marginTop: theme.spacing(3),
            padding: `0 ${theme.spacing(3)}`,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {posts.map((post) => {
            return (
              <PostCard
                key={post.id}
                variant="normal"
                cardSize="hr"
                post={{ ...post, data: { ...post.data, content: [] } }}
              />
            );
          })}
        </Stack>
        <Stack center sx={{ paddingTop: `${theme.spacing(3)}` }}>
          <Button
            onClick={() => router.push('/magazine')}
            buttonSize="md"
            sx={{ width: '246px' }}
          >
            もっと見る
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default PostsSummary;
