import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Link from 'next/link';

import Button from '@/contents/General/Button';
import Icon from '@/contents/General/Icon';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import useSearchCampSite from '@/modules/CampSite/public/useSearchCampSite';
import theme from '@/styles/theme';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 528px;
  background: url(/assets/images/common/bg_givemeahand.webp) no-repeat center;
  background-size: cover;
  z-index: -1;
`;

const GiveMeAHand = () => {
  const { total: num } = useSearchCampSite({
    where: {
      main_image_id: {
        _is_null: true,
      },
    },
  });
  return (
    <Container>
      <Stack column center sx={{ marginBottom: theme.spacing(7) }}>
        <Typography
          fontSize={24}
          color="white"
          fontWeight={900}
          sx={{
            paddingBottom: theme.spacing(3),
            fontFeatureSettings: '"palt"',
            letterSpacing: '0.15em',
          }}
        >
          みんなで、「いい日」を！
        </Typography>
        <Typography
          fontSize={14}
          color="white"
          fontWeight={500}
          textAlign="center"
        >
          iihiは、現在キャンプ場情報を募集中。
          <br />
          みんなで、キャンプの「いい日」をつくりませんか？
        </Typography>
        <Stack center>
          <Typography
            fontSize={18}
            color="white"
            fontWeight={700}
            sx={{ paddingTop: '37px' }}
          >
            残り
          </Typography>
          <Typography
            fontSize={64}
            color="white"
            fontWeight={900}
            sx={{ margin: `0 ${theme.spacing(2)}` }}
          >
            {num ?? 0}
          </Typography>
          <Typography
            fontSize={18}
            color="white"
            fontWeight={700}
            sx={{ paddingTop: '37px' }}
          >
            施設
          </Typography>
        </Stack>
      </Stack>
      <Link href={`/search/notentered/`}>
        <a>
          <Button
            buttonSize="lg"
            color="white"
            sx={{ marginBottom: theme.spacing(4), width: '290px' }}
          >
            情報募集施設を見る
          </Button>
        </a>
      </Link>
      <Stack alignCenter>
        <Icon iconType="arrow-right" color="white" />
        <Link href={`/contact/user/?category=キャンプ場の新規登録依頼`}>
          <a>
            <Typography fontSize={14} color="white" fontWeight={700}>
              キャンプ場の新規登録はこちら
            </Typography>
          </a>
        </Link>
      </Stack>
    </Container>
  );
};

export default GiveMeAHand;
