import Card from '@/contents/General/Card';
import type { CardSize } from '@/types/domain';
import type { ThemeDocument } from '@/types/prismic.generated';

const ThemeCard = ({
  theme,
  variant,
  cardSize,
}: {
  theme: ThemeDocument;
  variant: 'normal' | 'inner-title' | 'flex';
  cardSize: CardSize;
}) => {
  return (
    <Card
      variant={variant}
      cardSize={cardSize}
      imageUrl={theme.data.top_image.url ?? ''}
      title={theme.data.title?.toString()}
      description={theme.data.description?.toString()}
      link={`/theme/${theme.uid}`}
    />
  );
};
export default ThemeCard;
