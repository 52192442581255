// This file is generated by Slice Machine. Update with care!

import CampSitesSummary from './CampSitesSummary';
import GiveMeAHand from './GiveMeAHand';
import PostsSummary from './PostsSummary';
import ThemesSummary from './ThemesSummary';
import Topics from './Topics';

export { CampSitesSummary, GiveMeAHand, PostsSummary, ThemesSummary, Topics };

export const components = {
  camp_sites_summary: CampSitesSummary,
  give_me_a_hand: GiveMeAHand,
  posts_summary: PostsSummary,
  themes_summary: ThemesSummary,
  recommended_topics: Topics,
};
