import Box from '@mui/material/Box';
import { asText } from '@prismicio/helpers';
import React, { useContext } from 'react';

import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import CampSiteCard from '@/modules/CampSite/CampSiteCard';
import theme from '@/styles/theme';
import { Page } from '@/types/domain';
import type { CampSitesSummarySliceFetched } from '@/types/main';

const CampSitesSummary = ({
  slice,
}: {
  slice: CampSitesSummarySliceFetched;
}) => {
  const { page } = useContext(GlobalContext);
  const fetchedCampSite = slice.campSites;

  const cardSize =
    // eslint-disable-next-line no-nested-ternary
    slice.variation === 'large'
      ? 'lg'
      : slice.variation === 'wide'
      ? 'hr'
      : 'sm';

  const { title } = slice.primary;
  return (
    <Box sx={{ paddingBottom: theme.spacing(6) }}>
      <Stack column space={2} sx={{ padding: `0 ${theme.spacing(3)}` }}>
        <Typography
          fontSize={21}
          fontWeight={700}
          variant="h2"
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          {typeof title === 'string' ? (
            title
          ) : (
            <div dangerouslySetInnerHTML={{ __html: asText(title) ?? '' }} />
          )}
        </Typography>
        {!!slice.primary?.description && (
          <Typography fontSize={15}>
            {slice.primary.description as string}
          </Typography>
        )}
      </Stack>
      {page === Page.TOP ? (
        <Stack
          column
          space={4}
          sx={{
            overflowX: 'scroll',
            marginTop: theme.spacing(3),
            padding: `0 ${theme.spacing(3)}`,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Stack space={2}>
            {fetchedCampSite.map((campSite, index) => {
              return (
                index < 6 && (
                  <CampSiteCard
                    cardSize={cardSize}
                    key={campSite.id}
                    campSite={campSite}
                  />
                )
              );
            })}
          </Stack>
          <Stack space={2}>
            {fetchedCampSite.map((campSite, index) => {
              return (
                index > 5 &&
                index < 12 && (
                  <CampSiteCard
                    cardSize={cardSize}
                    key={campSite.id}
                    campSite={campSite}
                  />
                )
              );
            })}
          </Stack>
        </Stack>
      ) : (
        <Stack
          space={2}
          sx={{
            overflowX: 'scroll',
            marginTop: theme.spacing(3),
            padding: `0 ${theme.spacing(3)}`,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {fetchedCampSite.map((campSite, index) => {
            return (
              index < 12 && (
                <CampSiteCard
                  cardSize={cardSize}
                  key={campSite.id}
                  campSite={campSite}
                />
              )
            );
          })}
        </Stack>
      )}
    </Box>
  );
};

export default CampSitesSummary;
