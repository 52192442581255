import { asText } from '@prismicio/helpers';

import Card from '@/contents/General/Card';
import type { CardSize } from '@/types/domain';
import type { PostDocument } from '@/types/prismic.generated';

const PostCard = ({
  post,
  cardSize,
  variant,
}: {
  post: PostDocument;
  cardSize: CardSize;
  variant: 'normal' | 'inner-title' | 'flex';
}) => {
  return (
    <Card
      variant={variant}
      cardSize={cardSize}
      imageUrl={post.data.topImage?.url ?? ''}
      title={post.data.title?.toString()}
      description={asText(post.data.content)}
      link={`/magazine/${post.uid}`}
    />
  );
};
export default PostCard;
